/* --------------------------------------------------------------------------
| IMPORTS...
|-------------------------------------------------------------------------- */

import {
    handlePresenceConfirmation, handlePreloadMembersConfirmed,
    handleOpenScreenQuorumOnTimer, handleCloseScreenQuorumOnTimer,
} from '../../functions/functions-quoruns';

/* --------------------------------------------------------------------------
| FILE QUÓRUM EVENTS TIMER
|-------------------------------------------------------------------------- */

/* --------------------------------------------------------------------------
| EVENT -> VERIFICA SE EXISTE ITEM NO LOCALSTORAGE, SE EXISTE, RENDERIZA O ELEMENTO NO SIDEBAR...
|-------------------------------------------------------------------------- */
addEventListener('load', () => {
    let quorumSection = document.querySelector('#section__timer__quorum');

    if (quorumSection.dataset.quorum_started) {
        handleOpenScreenQuorumOnTimer()
    }
});

/* --------------------------------------------------------------------------
| EVENT -> EVENTO DE PRÉ LOAD DE TODOS OS MEMBROS CONFIRMADOS DO LOCALSTORAGE...
|-------------------------------------------------------------------------- */
addEventListener('load', handlePreloadMembersConfirmed);

/* --------------------------------------------------------------------------
| EVENT -> EVENTO DE ABERTURA DO QUORUM NO TIMER...
|-------------------------------------------------------------------------- */
window.Echo.channel("quorum-started") // Seleciona o canal de open...
    .listen(".quorum-started", (e) => { // Fica ouvindo...
        handleOpenScreenQuorumOnTimer(); // Chamada da função que cria o elemento do sidebar e seta no localstorage...
        window.location.reload();
    });

/* --------------------------------------------------------------------------
| EVENT -> EVENTO DE ENCERRAMENTO DO QUORUM NO TIMER...
|-------------------------------------------------------------------------- */
window.Echo.channel("quorum-closed") // Seleciona o canal de open...
    .listen(".quorum-closed", (e) => { // Fica ouvindo...
        handleCloseScreenQuorumOnTimer(); // Chamada da função que cria o elemento do sidebar e seta no localstorage...
        localStorage.removeItem('membersPresenceConfirmationQuorum'); // Limpa o localStorage...
        window.location.reload(); // Recarrega a página para limpar o html...
    });

/* --------------------------------------------------------------------------
| EVENT -> EVENTO DE PRESENÇA CONFIRMADA NO QUORUM NO TIMER...
|-------------------------------------------------------------------------- */
window.Echo.channel('quorum-presence') // Seleciona o canal...
    .listen('.quorum-presence', (e) => { // Fica escutando...
        handlePresenceConfirmation(e.memberId);
    });
